import React, { useState } from "react";
// import "./ForgotPassword.css";
import { LoginOutlet } from "../../Components/LoginOutlet/LoginOutlet";
import { Card } from "react-bootstrap";
import { strings } from "../../Assets/Strings";
import { InputField } from "../../Components/InputField/InputField";
import { CustomButton } from "../../Components/Button/button";
import SEO from "../../Components/SEO/seo";
import { validate } from "../../Utils/Validation";

export const ForgotPassword = () => {
  const[email,setEmail]=useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate("email_id", "r-e")
    if (isValid) {
      alert('success');
    }
  };
  return (
    <>
      <SEO
        title={"Forgot Password"}
        description={"MY-CPE: $199 for Unlimited Continuing Education"}
      />
      <LoginOutlet>
        <Card className="forgot_password_card">
          <Card.Body className="text-center	">
            <h1>{strings.RECOVER_PASSWORD}</h1>
            <p className="recovery_email">{strings.RECOVERY_EMAIL}</p>
            <InputField
              id={"email_id"}
              name={"email"}
              value={email}
              type={"text"}
              label={"Email"}
              title={"email"}
              onChange={handleEmailChange}
            />

            <CustomButton className="w-100 mt-4" onClick={handleSubmit}>{"Submit"}</CustomButton>
            <p className="signup-link">
              {strings.DONT_HAVE_ACCOUNT}
              <a className="signup-here" href={"/signup/individual"}>
                {strings.SIGN_UP_HERE}{" "}
              </a>
            </p>
            <p className="need_help">{strings.NEED_HELP}</p>
          </Card.Body>
        </Card>
      </LoginOutlet>
    </>
  );
};
