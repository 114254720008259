import React from "react";
import { svgs } from "../../Assets/Imgs";
import { LoginFooter } from "../../Components/LoginFooter/LoginFooter";
import { LoginNavbar } from "../../Components/LoginNavbar/LoginNavbar";
import { Container, Row, Col, Card } from "react-bootstrap";

export const LoginOutlet = ({ children, isSignup, navigate }) => {
  return (
    <div className="login-form">
      <LoginNavbar navigate={navigate} />
      <div className={isSignup ? "signup-main" : "login-main"}>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center">
              <img
                src={svgs.login_left_img}
                alt="login_left_img"
                className="logo"
              />
            </Col>
            <Col md={isSignup ? 6 : 5}>{children}</Col>
            {!isSignup && <Col md={1}></Col>}
          </Row>
        </Container>
      </div>
      <LoginFooter />
    </div>
  );
};
