import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./Assets/Scss/main.scss";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import { Login } from "./Pages/Login";
import { SignUp } from "./Pages/SignUp";
import { ForgotPassword } from "./Pages/ForgotPassword";
import { ResetPassword } from "./Pages/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup/individual" element={<SignUp />} />
        <Route path="/signup/teams" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
