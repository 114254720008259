import frame from "./frame.svg";
import arrow_left from "./arrow_left.svg";
import email from "./email.svg";
import background from "./background.svg";
import logo_with_text from "./logo_with_text.svg";
import apple from "./apple.svg";
import playstore from "./play.svg";
import browser from "./web.svg";
import lock from "./lock.svg";
import warning from "./warning.svg";
import right_blue from "./right_blue.svg";
import eye_icon_off from "./eye_icon_off.svg";
import eye_icon_on from "./eye_icon_on.svg";
import checkbox from "./checkbox.svg";
import warning_icon from "./warning_icon.svg";
import mastercard from "./mastercard.svg";
import plus from "./plus.svg";
import minus from "./minus.svg";
import warning_red from "./warning_red.svg";
import delete_icon from "./delete_icon.svg";

//SVGs
import login_left_img from "./login_left_img.png";
import trustpilot_img from "./trustpilot.png";
import google_img from "./google.png";
import pci_pcc_img from "./pci_pcc.png";
import stripe_logo_img from "./stripe_logo.png";
import price_tag_img from "./price_tag.png";
import inflation_img from "./inflation.png";
import purple_circle_img from "./purple_circle.png";
import white_circle_img from "./white_circle.png";
import congrats_img from "./congrats.png";

export const svgs = {
  //SVGs
  frame,
  arrow_left,
  email,
  background,
  logo_with_text,
  apple,
  playstore,
  browser,
  lock,
  warning,
  right_blue,
  eye_icon_off,
  eye_icon_on,
  checkbox,
  warning_icon,
  mastercard,
  plus,
  minus,
  warning_red,
  delete_icon,

  //pngs
  login_left_img,
  trustpilot_img,
  google_img,
  pci_pcc_img,
  stripe_logo_img,
  price_tag_img,
  inflation_img,
  purple_circle_img,
  white_circle_img,
  congrats_img,
};
