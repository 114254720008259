import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { CustomButton } from "../../Components/Button/button";
import { LoginOutlet } from "../../Components/LoginOutlet/LoginOutlet";
import { strings } from "../../Assets/Strings";
import { InputField } from "../../Components/InputField/InputField";
import { validate } from "../../Utils/Validation";
import SEO from "../../Components/SEO/seo";
import { SignInApi, ValidateEmailApi } from "../../Utils/Api";

export const Login = () => {
  const [showState, setShowState] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const toggleState = () => {
    setShowState(showState === 2 ? 1 : 2);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid =
      showState === 1
        ? validate("email_id", "r-e")
        : validate("email_id,password", "r-e,r-sc");

    if (isValid && showState === 1) {
      try {
        const response = await ValidateEmailApi(email);
        if (response.status_code === 1) {
          toggleState();
        } else {
          document
            .getElementById("container-email_id")
            .classList.add("input-error");

          document.getElementById("erroremail_id").innerHTML = response.message;
        }
      } catch (error) {
        console.error("Login failed:", error.message);
        alert("Login failed");
      }
    }

    if (isValid && showState === 2) {
      try {
        const response = await SignInApi(email, password);
        if (response.status_code === 1) {
          alert("Login Successfully");
        } else {
          document
            .getElementById("container-password")
            .classList.add("input-error");

          document.getElementById("errorpassword").innerHTML = response.message;
        }
      } catch (error) {
        console.error("Login failed:", error.message);
        alert("Login failed");
      }
    }
  };

  return (
    <>
      <SEO
        title={"Sign in my-CPE"}
        description={"MY-CPE: $199 for Unlimited Continuing Education"}
      />

      <LoginOutlet>
        <Card className="login-card">
          <Card.Body className="text-center">
            <h1>{strings.LOGIN_INTO_MY_CPE}</h1>
            <p className="welcome_back_text">{strings.WELCOME_BACK_TEXT}</p>

            <InputField
              id={"email_id"}
              name={"email"}
              value={email}
              type={"text"}
              label={"Email"}
              title={"email"}
              onChange={(e) => setEmail(e.target.value)}
              isCheckbox={showState === 2}
              isReadOnly={showState === 2}
            />

            {showState === 2 && (
              <>
                <InputField
                  id={"password"}
                  name={"password"}
                  value={password}
                  type={"password"}
                  label={"Password"}
                  title={"password"}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className="text-end mb-4">
                  <a className="forgot-p" href="/forgot-password">
                    {strings.FORGOT_PASSWORD}
                  </a>
                </div>
              </>
            )}
            
            <CustomButton className="w-100" onClick={handleSubmit}>
              {"Next"}
            </CustomButton>
            <p className="signup-link">
              {strings.DONT_HAVE_ACCOUNT}
              <a className="signup-here" href={"/signup/individual"}>
                {strings.SIGN_UP_HERE}
              </a>
            </p>
            <p className="need_help">{strings.NEED_HELP}</p>
          </Card.Body>
        </Card>
      </LoginOutlet>
    </>
  );
};
