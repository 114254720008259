import React, { useState } from "react";
// import "./InputField.css";
import { svgs } from "../../Assets/Imgs";
import { strings } from "../../Assets/Strings";

export const InputField = (props) => {
  const {
    type,
    name,
    value,
    onChange,
    label,
    id,
    title,
    isCheckbox,
    isEditText,
    onClickEditText,
    isMasterCardIcon,
    isReadOnly,
    simpleInput,
    placeholder,
    onKeyDown,
    onKeyUp,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeInput = (e) => {
    let errorid = "error" + id;
    let inputContainerId = `container-${id}`;
    document.getElementById(errorid).innerHTML = "";
    document.getElementById(inputContainerId).classList.remove("input-error");
    onChange(e);
  };

  return (
    <>
      {simpleInput ? (
        <div className="input-main">
          {label && <label className="simple-label">{label}</label>}
          <div className="input-field-container" id={`container-${id}`}>
            <input
              {...props}
              readOnly={isReadOnly}
              onChange={onChangeInput}
              type={showPassword ? "text" : type}
              className="input-field w-100"
              onKeyUp={onKeyUp}
              onKeyDown={
                onKeyDown
                  ? onKeyDown
                  : (evt) => {
                      if (
                        type === "number" &&
                        (evt.key === "e" ||
                          evt.key === "." ||
                          evt.key === "-" ||
                          evt.key === "+")
                      ) {
                        evt.preventDefault();
                      }
                    }
              }
              placeholder={placeholder ? placeholder : ""}
            />
          </div>
          <div className="message" id={"error" + id}></div>
        </div>
      ) : (
        <div className="input-main">
          <div
            className={`form-floating ${
              isMasterCardIcon ? "left-img-form-control" : ""
            } ${
              type === "password" || isCheckbox ? "right-img-form-control" : ""
            } ${isEditText ? "right-text-form-control" : ""}`}
            id={`container-${id}`}
          >
            {isMasterCardIcon && (
              <img
                src={svgs.mastercard}
                alt="mastercard"
                className={`mastercard ${isMasterCardIcon && "left-img"}`}
              />
            )}

            <input
              {...props}
              onChange={onChangeInput}
              type={showPassword ? "text" : type}
              className="form-control input-container"
              onKeyDown={
                onKeyDown
                  ? onKeyDown
                  : (evt) => {
                      if (
                        type === "number" &&
                        (evt.key === "e" ||
                          evt.key === "." ||
                          evt.key === "-" ||
                          evt.key === "+")
                      ) {
                        evt.preventDefault();
                      }
                    }
              }
              onKeyUp={onKeyUp}
              readOnly={isReadOnly}
              placeholder={placeholder ? placeholder : ""}
            />
            {label && <label className="label">{label}</label>}

            {type === "password" && (
              <img
                src={showPassword ? svgs.eye_icon_off : svgs.eye_icon_on}
                alt="eye-icon"
                className="eye_icon right-img"
                onClick={togglePasswordVisibility}
              />
            )}

            {isCheckbox && (
              <img
                src={svgs.checkbox}
                alt="checkbox"
                className="checkbox right-img"
              />
            )}

            {isEditText && (
              <a className="edit_btn" onClick={onClickEditText}>
                {strings.EDIT}
              </a>
            )}
          </div>

          <div className="err-msg" id={"error" + id} />
        </div>
      )}
    </>
  );
};
