import React from "react";
import { svgs } from "../../Assets/Imgs";
import { Container, Row, Col } from "react-bootstrap";
import { strings } from "../../Assets/Strings";

export const LoginFooter = () => {
  return (
    <Container>
      <div className="footer">
        <Row>
          <Col xs={12} md={6}>
            <p className="m-0 text">
              {strings.AT_2024}
              <span className="under-line m-1">
                <a className="m-0 text under-line" href="my-cpe.com">
                  {strings.MY_CPE_DOT_COM}
                </a>
              </span>
              {strings.ALL_RIGHTS_RESERVED}
            </p>
          </Col>
          <Col xs={12} md={6} className="text-end support-mail">
            <img src={svgs.email} alt="email_icon" className="me-2" />
            <a className="m-0 text" href="support@my-cpe.com">
              {strings.SUPPORT_MY_CPE_DOT_COM}
            </a>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
