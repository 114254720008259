import React from "react";
// import "./LoginNavbar.css";
import { svgs } from "../../Assets/Imgs";
import { Container, Row, Col } from "react-bootstrap";
import { strings } from "../../Assets/Strings";
import { useLocation } from "react-router-dom";

export const LoginNavbar = (props) => {
  const { isLogo } = props;
  const location = useLocation();

  return (
    <div className="main-menu">
      <Container>
        <Row className="align-items-center">
          <Col md={isLogo ? 4 : 12}>
            {location?.pathname !== "/login" && (
              <a
                onClick={() => {
                  props.navigate("/login");
                }}
              >
                <img
                  src={svgs.arrow_left}
                  className="back_arrow"
                  alt="arrow-left"
                />
                {strings.BACK}
              </a>
            )}
          </Col>
          {isLogo && (
            <>
              <Col md={4}>
                <div className="text-center">
                  <img src={svgs.logo_with_text} alt="arrow-left" />
                </div>
              </Col>
              <Col md={4}></Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};
