import { Button, Spinner } from "react-bootstrap";

// import "./button.css";

export const CustomButton = (props) => {
  const {
    children,
    style,
    className,
    onClick,
    disabled,
    isLoading,
    spinnerVariant,
    variant,
    spinnerSize,
    type,
    id,
    btnRef,
  } = props;
  return (
    <Button
      id={id}
      ref={btnRef}
      variant={variant ? variant : "primary"}
      type={type}
      className={`custom-btn ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          variant={spinnerVariant ? spinnerVariant : "light"}
          size={spinnerSize ? spinnerSize : "sm"}
        />
      ) : (
        children
      )}
    </Button>
  );
};
