import { Modal, Button, Row, Col } from "react-bootstrap";
import { svgs } from "../../Assets/Imgs";
import { strings } from "../../Assets/Strings";
// import "./honestPricingModal.css";

export const HonestPricingModal = (props) => {
  const list = [
    "Honest pricing without fake sales or discounts.",
    "Prices set at $199 from 2021, maintaining stability despite inflation.",
    "We help you save 70% or more money compared to competitors.",
    "Our objective is to make continuing education affordable and accessible.",
    "Delivering over 3 million credits annually.",
    "Ensuring quality and consistency in all courses and materials.",
  ];
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="honest-pricing-modal"
    >
      <div className="honest-content">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col>
              <div className="honest-main">
                <div className="title">{strings.HONEST_PRICING}</div>
                <p>{strings.HONEST_DESC}</p>

                {list.map((item) => {
                  return (
                    <ul>
                      <li>
                        <img src={svgs.right_blue} alt="right_blue" />
                        {item}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </Col>
            <Col>
              <img src={svgs.inflation_img} alt="inflation_img" />
            </Col>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};
