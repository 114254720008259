import React, { useState } from "react";
import { LoginOutlet } from "../../Components/LoginOutlet/LoginOutlet";
import { Card } from "react-bootstrap";
import { strings } from "../../Assets/Strings";
import { InputField } from "../../Components/InputField/InputField";
import { CustomButton } from "../../Components/Button/button";
import SEO from "../../Components/SEO/seo";
import { validate } from "../../Utils/Validation";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate("password,confirm_password", "r-sc,r-sc");
    if (isValid) {
      if (password !== confirmPassword) {
        alert("Passwords do not match");
        return;
      } else {
        alert("Password Reset Successfully");
      }
    }
  };

  return (
    <>
      <SEO
        title={"Recover Password"}
        description={"MY-CPE: $199 for Unlimited Continuing Education"}
      />
      <LoginOutlet>
        <Card className="reset_password_card">
          <Card.Body className="text-center	">
            <h1>{strings.LOGIN_INTO_MY_CPE}</h1>
            <p className="change_password">{strings.CHANGE_ACCOUNT_PASSWORD}</p>

            <InputField
              id={"email_id"}
              name={"email"}
              type={"text"}
              label={"Email"}
              title={"email"}
              isCheckbox
              isReadOnly
            />
            <InputField
              id={"password"}
              name={"password"}
              value={password}
              type={"password"}
              label={"Password *"}
              title={"password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputField
              id={"confirm_password"}
              name={"confirm_password"}
              value={confirmPassword}
              type={"password"}
              label={"Confirm Password *"}
              title={"password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <CustomButton className="w-100 mt-4" onClick={handleSubmit}>
              {"Reset Password"}
            </CustomButton>
            <p className="need_help">{strings.NEED_HELP}</p>
          </Card.Body>
        </Card>
      </LoginOutlet>
    </>
  );
};
