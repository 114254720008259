import { Navigate } from "react-router-dom";
const BASE_URL = "https://learning.testing-website.in/rest";

export const ValidateEmailApi = async (email) => {
  const formData = new FormData();
  formData.append("email", email);

  const response = await fetch(`${BASE_URL}/login/check_email/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) throw new Error("User not found");

  return await response.json();
};

export const SignInApi = async (email, password) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  const response = await fetch(`${BASE_URL}/login/dologin/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) throw new Error("User not found");

  return await response.json();
};

export const getSubscriptionPrice = async (countryCode) => {
  const formData = new FormData();
  formData.append("country_code", countryCode);

  const response = await fetch(`${BASE_URL}/subscriptions/get_price/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) throw new Error("User not found");

  return await response.json();
};

export const applyPromoCode = async (promoCode) => {
  const formData = new FormData();
  formData.append("promo_code", promoCode);

  const response = await fetch(`${BASE_URL}/promo_code/apply_promo_code/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) throw new Error("User not found");

  return await response.json();
};

export const signUpApi = async (data) => {
  const formData = new FormData();
  if (data && Object.keys(data).length > 0) {
    for (var pair of Object.entries(data)) {
      formData.append(pair[0], pair[1]);
    }
  }

  const response = await fetch(`${BASE_URL}/users/signup_individual_firm/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) throw new Error("User not found");

  return await response.json();
};

export const commonApiHandler = async (endPoint, method, data) => {
  const formData = new FormData();
  const user = JSON.parse(localStorage.getItem("user"));
  formData.append("user_id", user.id);
  formData.append("accesstoken", user.accesstoken);
  if (data && Object.keys(data).length > 0) {
    for (var pair of Object.entries(data)) {
      formData.append(pair[0], pair[1]);
    }
  }

  const response = await fetch(`${BASE_URL}/${endPoint}`, {
    method: method,
    body: formData,
  });

  const res = await response?.json();
  if (!response.ok) throw new Error("Unexpected Error");
  if (res?.message === "Invalid Userid or Accesstoken") {
    localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("user");
    Navigate("/");
  }
  return res;
};
