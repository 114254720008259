export const strings = {
  BACK: "Back",
  AT_2024: "©2024 -",
  MY_CPE_DOT_COM: "my-cpe.com",
  SUPPORT_MY_CPE_DOT_COM: "support@my-cpe.com",
  ALL_RIGHTS_RESERVED: "All rights reserved",
  LOGIN_INTO_MY_CPE: "Log In",
  WELCOME_BACK_TEXT: "Welcome back! Use your my-CPE Account",
  FORGOT_PASSWORD: "Forgot password?",
  DONT_HAVE_ACCOUNT: "Don’t have an account?",
  SIGN_UP_HERE: "Sign up here",
  NEED_HELP:"Need help?",
  INDIVIDUAL_SUBSCRIPTION: "Individual Subscription",
  UNLIMITED_LEARNING_CREDITS: "Unlimited Learning Credits",
  NO_1_CONTINUING: "#1 Continuing Education Platform: Your Path to Excellence",
  NO_1_CONTINUING_1:
    "#1 Continuing Education Platform: Your Path to Excellence",
  TRUSTED_BY_PROFESSIONALS: "Trusted by 250,000+ Professionals",
  // ADVANCED_LEARNING: "Advanced Learning Management System",
  // EVERY_TEAM_MEMBERS: "Every Team Member's Compliance Companion",
  // UPSKILL_YOUR_TALENT: "Upskill Your Talent, Fuel Your Team's Progress",
  PERSONAL_INFO: "Personal Info",
  PROFESSIONAL_INFO: "Professional Info",
  SEATS_AND_PRICING: "Seats and Pricing",
  PAYMENT_DETAILS: "Payment Details",
  TOTAL: "Total",
  INDIVIDUAL: "Individual",
  TEAM_PLAN: "Team Plan",
  POWERED_BY: "Powered by",
  NUMBER_OF_SEATS: "Number of Seats",
  APPLIED_PROMO: "Applied Promo",
  CONTINUE: "continue",
  CONGRATULATIONS: "Congratulations",
  EDIT: "Edit",

  AVAILABLR_ON: "Available on",
  SUBSRIBE_NOW: "Subscribe Now",
  CONTINUING_EDUCATION: "#1 Continuing Education Platform",
  GUARANTEED_SAFE: "Guaranteed safe & secure checkout",

  PAY_SECURELY: "Pay Securely $199",
  HONEST_PRICING: "Honest Pricing, Clear Value!",
  HONEST_DESC: "Lorem Ipsum is simply dummy text",

  RECOVER_PASSWORD: "Recover Password",
  RECOVERY_EMAIL: "Enter your email you wish to recover password for",
  ERROR_MSG: "Please enter a valid email address",
  CHANGE_ACCOUNT_PASSWORD: "Change your account password",

  BY_SIGNING_UP: "By signing up, I accept the myCPE",
  TERMS_OF_SERVICES: "Terms of Services",
  PRIVACY_NOTICE: "Privacy Notice",
  AND: "and",
  NO_OF_HOURS: "No of Hours",
  COURSES: "Courses",
  TOPICS: "Topics",
  SPEAKER: "Speaker",
};
