import { Modal, Button, Row, Col } from "react-bootstrap";
import { svgs } from "../../Assets/Imgs";
import { strings } from "../../Assets/Strings";
import { CustomButton } from "../Button/button";

export const SubscribedModal = (props) => {
  let msg;
  if (props?.message !== "" || props?.message !== undefined) {
    msg = props?.message?.split("from ");
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="subscribed-modal"
    >
      <Modal.Body>
        <Row className="text-center">
          <Col>
            <img src={svgs.congrats_img} alt="congrats_img" />
            <h4>{strings.CONGRATULATIONS}</h4>
            <p>
              {msg[0] + "from "}
              <span>{msg[1]}</span>
            </p>
            <CustomButton className="w-100" onClick={props?.onClickContinue}>
              {strings.CONTINUE}
            </CustomButton>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
