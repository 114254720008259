export const validate = (elements, validation) => {
  //   let red_err_img = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <rect width="18" height="18" fill="white"/>
  // <path d="M9 1.125C4.66875 1.125 1.125 4.66875 1.125 9C1.125 13.3313 4.66875 16.875 9 16.875C13.3313 16.875 16.875 13.3313 16.875 9C16.875 4.66875 13.3313 1.125 9 1.125ZM8.38125 4.5H9.61875V10.6875H8.38125V4.5ZM9 14.0625C8.55 14.0625 8.15625 13.6687 8.15625 13.2188C8.15625 12.7688 8.55 12.375 9 12.375C9.45 12.375 9.84375 12.7688 9.84375 13.2188C9.84375 13.6687 9.45 14.0625 9 14.0625Z" fill="#F04438"/>
  // </svg>`;

  let error = "";
  let elementarray = elements.split(",");
  let validatearray = validation.split(",");

  for (let i = 0; i < elementarray.length; i++) {
    let e = document.getElementById(elementarray[i]);
    let inputContainerId = `container-${elementarray[i]}`; // for border color
    let errorid = "error" + elementarray[i];
    let validations = validatearray[i].split("-");

    if (
      e.type == "file" ||
      e.type == "text" ||
      e.type == "password" ||
      e.type == "textarea"
    ) {
      if (e.value == "" && validations[0] == "r") {
        error += "Please Enter " + e.title + "\n";
        document.getElementById(inputContainerId).classList.add("input-error");

        document.getElementById(errorid).innerHTML = `This field is required.`;
        e.focus();
        return false;
      }

      if (
        e.value != "" &&
        (validations[0] == "e" ||
          validations[1] == "e" ||
          validations[2] == "e")
      ) {
        if (!emailvalidation(e.value)) {
          error += "Invalid " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");
          document.getElementById(
            errorid
          ).innerHTML = `Please Enter Valid Email Address.`;
          e.focus();
          return false;
        }
      }
      if (
        e.value != "" &&
        (validations[0] == "n" ||
          validations[1] == "n" ||
          validations[2] == "n")
      ) {
        if (isNaN(e.value)) {
          error += "Invalid " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");
          document.getElementById(
            errorid
          ).innerHTML = `Please Enter Numeric Value.`;
          e.focus();
          return false;
        }
      }
      if (
        e.value != "" &&
        (validations[0] == "u" ||
          validations[1] == "u" ||
          validations[2] == "u")
      ) {
        if (!isURL(e.value)) {
          error += "Invalid " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");
          document.getElementById(
            errorid
          ).innerHTML = `Please Enter Valid URL.`;
          e.focus();
          return false;
        }
      }
      if (
        e.value != "" &&
        (validations[0] == "sc" ||
          validations[1] == "sc" ||
          validations[2] == "sc")
      ) {
        if (!isSpclChar(e.value)) {
          error += "Invalid " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");
          document.getElementById(
            errorid
          ).innerHTML = `Password should have special characters.`;
          e.focus();
          return false;
        }
      }

      if (
        e.value != "" &&
        (validations[0] == "16" ||
          validations[1] == "16" ||
          validations[2] == "16")
      ) {
        if (e.value.length < 18) {
          error += "Please Enter " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");

          document.getElementById(
            errorid
          ).innerHTML = `Please enter valid card number.`;
          e.focus();
          return false;
        }
      }

      if (
        e.value != "" &&
        (validations[0] == "ed" ||
          validations[1] == "ed" ||
          validations[2] == "ed")
      ) {
        let split = e.value.split("/");

        var today = new Date(); // gets the current date
        var today_mm = today.getMonth() + 1; // extracts the month portion
        var today_yy = today.getFullYear() % 100; // extracts the year portion and changes it from yyyy to yy format

        if (today_mm < 10) {
          // if today's month is less than 10
          today_mm = "0" + today_mm; // prefix it with a '0' to make it 2 digits
        }

        var mm = split[0]; // get the mm portion of the expiryDate (first two characters)
        var yy = split[split.length - 1]; // get the yy portion of the expiryDate (from index 3 to end)
        let check = yy > today_yy || (yy == today_yy && mm >= today_mm);
        if (!check) {
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");

          document.getElementById(errorid).innerHTML =
            "The expiry date needs to be greater than today.\n";
          e.focus();
          return false;
        } else {
          // all good because the yy from expiryDate is greater than the current yy
          //   // or if the yy from expiryDate is the same as the current yy but the mm
          //   // from expiryDate is greater than the current mm
        }
      }

      if (
        e.value != "" &&
        (validations[0] == "cvv" ||
          validations[1] == "cvv" ||
          validations[2] == "cvv")
      ) {
        if (e.value.length < 3) {
          error += "Please Enter " + e.title + "\n";
          document
            .getElementById(inputContainerId)
            .classList.add("input-error");

          document.getElementById(
            errorid
          ).innerHTML = `Please enter valid cvv.`;
          e.focus();
          return false;
        }
      }

      if (error == "") {
        document.getElementById(errorid).innerHTML = "";
        error = "";
      }
    } else if (e.type == "select-one" && validations[0] == "r") {
      if (e.selectedIndex == 0) {
        error += "Please Select " + e.title + "\n";
        document.getElementById(inputContainerId).classList.add("input-error");
        document.getElementById(
          errorid
        ).innerHTML = `Please Select Atleast One Value.`;
        e.focus();
        return false;
      } else {
        document.getElementById(errorid).innerHTML = "";
      }
    } else if (e.type == "select-multiple" && validations[0] == "r") {
      if (e.selectedIndex == -1) {
        error += "Please Select " + e.title + "\n";
        document.getElementById(inputContainerId).classList.add("input-error");
        document.getElementById(
          errorid
        ).innerHTML = `Please Select Atleast One Value.`;
        e.focus();
        return false;
      } else {
        document.getElementById(errorid).innerHTML = "";
      }
    } else if (e.type == "checkbox" && validations[0] == "r") {
      if (!e.checked) {
        error += "Please Check " + e.title + "\n";
        document.getElementById(inputContainerId).classList.add("input-error");
        document.getElementById(
          errorid
        ).innerHTML = `Please Select Atleast One value.`;
        e.focus();
        return false;
      } else {
        document.getElementById(errorid).innerHTML = "";
      }
    }
  }
  if (error != "") {
    //alert(error);
    return false;
  } else {
    return true;
  }
};

function emailvalidation(email) {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(email) == false) {
    return false;
  } else {
    return true;
  }
}

function isURL(s) {
  var regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(s);
}

function isSpclChar(str) {
  var iChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";
  for (var i = 0; i < str.length; i++) {
    if (iChars.indexOf(str.charAt(i)) != -1) {
      return true;
    }
  }
  return false;
}

function encryptString(str) {
  var len = str.length;
  var estr = "";
  for (let i = 0; i < len; i++) {
    if (estr == "") {
      estr = str.charCodeAt(i);
    } else {
      estr += "a" + str.charCodeAt(i);
    }
  }
  return estr;
}
