import React, { useEffect, useRef, useState } from "react";
import { svgs } from "../../Assets/Imgs";
import { strings } from "../../Assets/Strings";
import { Container, Row, Col, Card, Nav, Form } from "react-bootstrap";
import { CustomButton } from "../../Components/Button/button";
import { InputField } from "../../Components/InputField/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { HonestPricingModal } from "../../Components/HonestPricingModal/honestPricingModal";
import ReCAPTCHA from "react-google-recaptcha";
import { validate } from "../../Utils/Validation";
import SEO from "../../Components/SEO/seo";
import { LoginOutlet } from "../../Components/LoginOutlet/LoginOutlet";
import { SubscribedModal } from "../../Components/SubscribedModal/subscribedModal";
import {
  ValidateEmailApi,
  applyPromoCode,
  getSubscriptionPrice,
  signUpApi,
} from "../../Utils/Api";

export const SignUp = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const submitBtn = useRef(null);

  const [endPoint, setEndpoint] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [congratsModalShow, setcongratsModalShow] = useState(false);
  const [formSignup, setFormSignup] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    noOfSeats: "",
    promoCode: "",
    cardHolderName: "",
    cardNo: "",
    cvv: "",
    expiryDate: "",
    address: "",
    noOfSeats: Number(1),
    termsCondition: false,
    captchaToken: "",
  });
  const [isEmailNew, setIsEmailNew] = useState(false);
  const [isAcceptedTermsPrivacy, setIsAcceptedTermsPrivacy] = useState(false);
  const [subsciptionValue, setSubscriptionValue] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [successfulMessage, setSuccessfulMessage] = useState("");
  const [promoCodeDetails, setPromoCodeDetails] = useState({});
  const [promo, setPromo] = useState("");
  const [loader, setLoader] = useState(false);

  let staticPromoCode = "FIRM10";

  useEffect(() => {
    if (location?.pathname?.includes("individual")) {
      setEndpoint("individual");
    } else {
      setEndpoint("teams");
    }
  }, [location?.pathname]);

  useEffect(() => {
    const getSubscriptionPlanValue = async () => {
      try {
        const response = await getSubscriptionPrice("US");
        if (response?.status_code === 1) {
          setSubscriptionValue(response?.data?.fee);
        } else {
          alert("subscription plan failed");
        }
      } catch (error) {
        console.error("subscription plan:", error.message);
        alert("subscription plan failed");
      }
    };

    getSubscriptionPlanValue();
  }, [location?.pathname]);

  useEffect(() => {
    if (endPoint === "teams") {
      applyPromo();
    }
  }, [subsciptionValue, location?.pathname]);

  const applyPromo = async () => {
    try {
      const response = await applyPromoCode(staticPromoCode);
      if (response?.status_code === 1) {
        setPromoCodeDetails(response?.data);
        if (response?.data?.type === "percent") {
          let value = (subsciptionValue / 100) * response?.data?.percentage;
          setPromo(Number(value).toFixed(2));
        }
      } else {
        alert("promo code failed");
      }
    } catch (error) {
      console.error("promo code:", error.message);
      alert("promo code failed");
    }
  };

  const onKeyDownExpiryDate = (e) => {
    var code = e.keyCode;
    var allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      let splitted = e.target.value.split("");
      if (splitted.length === 3) {
        e.target.value = splitted[0];
        e.preventDefault();
      } else {
        return;
      }
    }

    if ([191].indexOf(code) !== -1) {
      //* 191 - '\ not allowed' *//
      e.preventDefault();
    }
  };

  const onChangeInputs = (e) => {
    if (e.target.name === "cvv") {
      e.target.value = e.target.value.replace(/[^\dA-Z]/g, "").trim();
    }

    if (e.target.name === "cardNo") {
      e.target.value = e.target.value
        .toLowerCase()
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    }

    if (e.target.name === "expiryDate") {
      e.target.value = e.target.value
        .replace(
          /^([1-9]\/|[2-9])$/g,
          "0$1/" // 3 > 03/
        )
        .replace(
          /^(0[1-9]|1[0-2])$/g,
          "$1/" // 11 > 11/
        )
        .replace(
          /^([0-1])([3-9])$/g,
          "0$1/$2" // 13 > 01/3
        )
        .replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
          "$1/$2" // 141 > 01/41
        )
        .replace(
          /^([0]+)\/|[0]+$/g,
          "0" // 0/ > 0 and 00 > 0
        )
        .replace(
          /[^\d\/]|^[\/]*$/g,
          "" // To allow only digits and `/`
        )
        .replace(
          /\/\//g,
          "/" // Prevent entering more than 1 `/`
        );
    }

    setFormSignup({ ...formSignup, [e.target.name]: e.target.value });
  };

  const onClickContinue = () => {
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid;
    let emailEle = document.getElementById("email_id");
    let checkIfError =
      document.getElementById("erroremail_id").textContent === "";

    if (checkIfError) {
      isValid =
        endPoint === "individual"
          ? validate(
              "first_name,last_name,email_id,card_holder_name,card_no,cvv,expiry_date,your_address",
              "r,r,r-e,r,r-16,r-cvv,r-ed,r"
            )
          : validate(
              "first_name,last_name,company_name,email_id,card_holder_name,card_no,cvv,expiry_date,your_address,no_of_seats",
              "r,r,r,r-e,r,r-16,r-cvv,r-ed,r,r-n"
            );
    } else {
      emailEle.focus();
    }

    if (isValid) {
      if (isAcceptedTermsPrivacy) {
        // if (captchaToken !== "") {
        setLoader(true);
        let data = {
          firstname: formSignup?.firstName,
          lastname: formSignup?.lastName,
          email: formSignup?.email,
          card_holder_name: formSignup?.cardHolderName,
          card_no: formSignup?.cardNo,
          cvv: formSignup?.cvv,
          expiry_date: formSignup?.expiryDate,
          address: formSignup?.address,
          subscription_type: endPoint === "individual" ? 1 : 2,
          country_code: "US",
          captcha_token: "captchaToken",
        };

        if (endPoint === "teams") {
          data.company_name = formSignup?.companyName;
          data.no_of_seats = formSignup?.noOfSeats;
          data.promo_code = formSignup?.promoCode || staticPromoCode;
        }
        const response = await signUpApi(data);
        if (response?.status_code === 1) {
          await setSuccessfulMessage(response?.message);
          await setcongratsModalShow(true);
          await setLoader(false);
          // setTimeout(() => {
          //   navigate("/login");
          // }, 5000);
        } else {
          await setLoader(false);
          console.error("Signup failed:", response.message);
          alert("Signup failed");
        }
        // } else {
        //   document.getElementById("error-recaptcha").innerHTML =
        //     "Please verify captcha";
        // }
      } else {
        document.getElementById("error-terms-privacy-policy").innerHTML =
          "Please Select Terms and conditions";
      }
    }
  };

  const validateEmail = async (value) => {
    try {
      const response = await ValidateEmailApi(value);
      if (response.status_code === 1) {
        document
          .getElementById("container-email_id")
          .classList.add("input-error");
        document.getElementById("erroremail_id").innerHTML =
          "email already used";
      } else {
        setIsEmailNew(true);
        return true;
      }
    } catch (error) {
      console.error("validate email failed:", error.message);
      alert("validate email failed");
    }
  };

  const onBlurEmail = async (e) => {
    if (e.target.value !== "") {
      const isValid = validate("email_id", "r-e");
      if (isValid) {
        validateEmail(e.target.value);
      }
    }
  };

  const onClickEditText = () => {
    setIsEmailNew(false);
    setFormSignup({ ["email"]: "" });
  };

  return (
    <div className="signup-form-main">
      <SEO
        title={"Sign up my-CPE"}
        description={"MY-CPE: $199 for Unlimited Continuing Education"}
      />

      <LoginOutlet isSignup navigate={navigate}>
        <Card className="signup-card">
          <div className="heading mb-4">
            <Row className="align-items-center">
              <Col md={12}>
                <h3>{strings.SUBSRIBE_NOW}</h3>
              </Col>
              <Col md={6}>
                <p>{strings.CONTINUING_EDUCATION}</p>
              </Col>
              <Col md={6}>
                <Form.Check
                  type="switch"
                  checked={endPoint === "teams"}
                  id="custom-switch"
                  label={
                    endPoint === "individual"
                      ? strings.INDIVIDUAL
                      : strings.TEAM_PLAN
                  }
                  reverse
                  onChange={(e) => {
                    if (endPoint === "individual") {
                      setEndpoint("teams");
                      navigate("/signup/teams");
                    } else {
                      setEndpoint("individual");
                      navigate("/signup/individual");
                    }
                  }}
                />
              </Col>
            </Row>
          </div>

          <Form className="signup-form-details" onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="info-title">
                  <p className="title">
                    {endPoint === "individual"
                      ? strings.PERSONAL_INFO
                      : strings.PROFESSIONAL_INFO}
                  </p>
                  <div className="divider-x" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputField
                  id={"first_name"}
                  name={"firstName"}
                  type={"text"}
                  label={"First name"}
                  title={"first name"}
                  onChange={onChangeInputs}
                  value={formSignup?.firstName}
                />
              </Col>
              <Col>
                <InputField
                  name={"lastName"}
                  type={"text"}
                  label={"Last name"}
                  id={"last_name"}
                  title={"last name"}
                  onChange={onChangeInputs}
                  value={formSignup?.lastName}
                />
              </Col>

              {endPoint === "teams" && (
                <Col md={12}>
                  <InputField
                    name={"companyName"}
                    type={"text"}
                    label={"Company Name"}
                    id={"company_name"}
                    title={"company name"}
                    onChange={onChangeInputs}
                    value={formSignup?.companyName}
                  />
                </Col>
              )}

              <Col md={12}>
                <InputField
                  name={"email"}
                  type={"text"}
                  label={"Email"}
                  id={"email_id"}
                  title={"email"}
                  onChange={onChangeInputs}
                  value={formSignup?.email}
                  onBlur={onBlurEmail}
                  isEditText={isEmailNew}
                  disabled={isEmailNew}
                  onClickEditText={onClickEditText}
                />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="info-title">
                  <p className="title">{strings.PAYMENT_DETAILS}</p>
                  <div className="divider-x" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputField
                  name={"cardHolderName"}
                  type={"text"}
                  label={"Card holder name"}
                  id={"card_holder_name"}
                  title={"card holder name"}
                  onChange={onChangeInputs}
                  value={formSignup?.cardHolderName}
                />
              </Col>
              <Col md={6}>
                <InputField
                  name={"cardNo"}
                  type={"text"}
                  label={"Card No."}
                  isMasterCardIcon
                  id={"card_no"}
                  title={"card no"}
                  onChange={onChangeInputs}
                  value={formSignup?.cardNo}
                  maxLength={19}
                />
              </Col>

              <Col md={6}>
                <InputField
                  name={"cvv"}
                  type={"password"}
                  label={"CVV"}
                  id={"cvv"}
                  title={"cvv"}
                  onChange={onChangeInputs}
                  value={formSignup?.cvv}
                  maxLength={4}
                />
              </Col>
              <Col md={6}>
                <InputField
                  name={"expiryDate"}
                  type={"text"}
                  label={"Expiry Date (MM/YY)"}
                  id={"expiry_date"}
                  title={"expiry date"}
                  onChange={onChangeInputs}
                  value={formSignup?.expiryDate}
                  maxLength={5}
                  onKeyDown={(e) => onKeyDownExpiryDate(e)}
                />
              </Col>

              <Col md={12}>
                <InputField
                  name={"address"}
                  type={"text"}
                  label={"Address"}
                  id={"your_address"}
                  title={"your address"}
                  onChange={onChangeInputs}
                  value={formSignup?.address}
                />
              </Col>
            </Row>
            <Row className="align-items-center">
              {endPoint === "teams" && (
                <>
                  <Col md={12}>
                    <div className="info-title">
                      <p className="title">{strings.SEATS_AND_PRICING}</p>
                      <div className="divider-x" />
                    </div>
                  </Col>
                  <Col md={12}>
                    <p className="no-of-seats">{strings.NUMBER_OF_SEATS}</p>
                  </Col>

                  <Col>
                    <div className="seats-counter">
                      <div
                        className="seats-counter-btn"
                        onClick={() => {
                          if (formSignup?.noOfSeats > 1) {
                            setFormSignup({
                              ...formSignup,
                              ["noOfSeats"]: formSignup?.noOfSeats - 1,
                            });

                            let value =
                              ((subsciptionValue *
                                (formSignup?.noOfSeats - 1)) /
                                100) *
                              promoCodeDetails?.percentage;
                            setPromo(Number(value).toFixed(2));
                          }
                        }}
                      >
                        <img src={svgs.minus} alt="minus" />
                      </div>
                      <InputField
                        name={"noOfSeats"}
                        type={"number"} // there is no need of validation so used number
                        id={"no_of_seats"}
                        title={"no of seats"}
                        onChange={onChangeInputs}
                        value={formSignup?.noOfSeats}
                        simpleInput
                      />
                      <div
                        className="seats-counter-btn"
                        onClick={() => {
                          setFormSignup({
                            ...formSignup,
                            ["noOfSeats"]: formSignup?.noOfSeats + 1,
                          });

                          let value =
                            ((subsciptionValue * (formSignup?.noOfSeats + 1)) /
                              100) *
                            promoCodeDetails?.percentage;
                          setPromo(Number(value).toFixed(2));
                        }}
                      >
                        <img src={svgs.plus} alt="plus" />
                      </div>
                    </div>
                  </Col>

                  <Col>
                    <p className="seats-price text-end">{`$${
                      subsciptionValue * formSignup?.noOfSeats
                    }`}</p>
                  </Col>

                  <Col md={12}>
                    <div className="seats divider-x" />
                  </Col>

                  <Col>
                    <div className="promo-txt">
                      <p>{strings.APPLIED_PROMO}</p>
                      <img src={svgs.delete_icon} alt="delete_icon" />
                    </div>
                  </Col>

                  <Col>
                    <p className="promo-price text-end">{`-$${promo}`}</p>
                  </Col>

                  <Col md={12}>
                    <div className="dark divider-x" />
                  </Col>

                  <Col>
                    <p className="teams total-txt">{strings.TOTAL}</p>
                  </Col>

                  <Col>
                    <p className="total-price text-end">{`$${
                      subsciptionValue * formSignup?.noOfSeats - promo
                    }`}</p>
                  </Col>
                </>
              )}
              <Col md={12}>
                <div
                  className="terms-privacy-checkbox"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Form.Check
                    type={"checkbox"}
                    id={`privacy-terms`}
                    checked={isAcceptedTermsPrivacy === true}
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      onChange={(e) => {
                        setIsAcceptedTermsPrivacy(!isAcceptedTermsPrivacy);
                        document.getElementById(
                          "error-terms-privacy-policy"
                        ).innerHTML = "";
                      }}
                    />
                    <Form.Check.Label>
                      {strings.BY_SIGNING_UP}
                      <a href="https://www.google.com/" target={"_blank"}>
                        {strings.TERMS_OF_SERVICES}
                      </a>
                      {strings.AND}
                      <a href="https://www.google.com/" target={"_blank"}>
                        {strings.PRIVACY_NOTICE}
                      </a>
                    </Form.Check.Label>
                  </Form.Check>
                  <div className="err-msg" id={"error-terms-privacy-policy"} />
                </div>
              </Col>

              {/* <Col>
                <div className="reCaptcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={"6LeSlsAUAAAAAH2zo6IcEWhyzo_yl8Hhm3UIQZfC"}
                    onChange={(value) => {
                      setCaptchaToken(value);
                      document.getElementById("error-recaptcha").innerHTML = "";
                      setTimeout(() => {
                        submitBtn.current.focus();
                      }, 500);
                    }}
                  />
                  <div className="err-msg" id={"error-recaptcha"} />
                </div>
              </Col> */}

              <Col md={12}>
                <div className="pcc-stripe">
                  {strings.POWERED_BY}
                  <img
                    src={svgs.stripe_logo_img}
                    alt="stripe_logo"
                    className="stripe-logo"
                  />
                  <div className="divider-y" />
                  <img src={svgs.pci_pcc_img} alt="pci_pcc" />
                </div>
              </Col>

              {endPoint === "individual" && (
                <div className="total-pricing text-center">
                  <Row className="align-items-center">
                    <Col>
                      <p className="total-txt text-left">{strings.TOTAL}</p>
                    </Col>
                    <Col className="text-end">
                      <p className="price">{`$${subsciptionValue} / `}</p>
                      <p className="months">{"12 Months"}</p>
                    </Col>
                  </Row>
                </div>
              )}

              <Col md={12}>
                <CustomButton
                  className="w-100"
                  type="submit"
                  isLoading={loader}
                  btnRef={submitBtn}
                >
                  {strings.PAY_SECURELY}
                </CustomButton>
              </Col>
              <div
                className="honest-pricing"
                onClick={() => setModalShow(true)}
              >
                <img src={svgs.warning} alt="warning" height={18} width={18} />
                <a>{strings.HONEST_PRICING}</a>
              </div>

              <Col md={12}>
                <div className="dark divider-x" />
              </Col>

              <p className="need-help text-center">{strings.NEED_HELP}</p>
            </Row>
          </Form>
        </Card>
      </LoginOutlet>

      <HonestPricingModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubscribedModal
        show={congratsModalShow}
        onHide={() => setModalShow(false)}
        message={successfulMessage}
        onClickContinue={onClickContinue}
      />
    </div>
  );
};
